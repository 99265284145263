import React, { Component } from "react";
import {
    DataTable,
    Table,
    TableHead,
    TableRow,
    TableHeader,
    TableBody,
    TableCell,
    Button,
    Modal,
} from "carbon-components-react";
import { Row } from "gatsby-theme-carbon/src/components/Grid";
import "../../gatsby-theme-carbon/components/ResourceCardCL/resource-card.scss";
import "../../gatsby-theme-carbon/components/ResourceCardCL/resource-card-group.scss";
import { Information16 } from "@carbon/icons-react";

import "./checklistReleaseNotes.scss";

import { withPrefix } from "gatsby";

class ChecklistReleaseNotes extends Component {
    constructor(props) {
        super(props);
        this.state = { LevelsInfoIconModalIsOpen: false };
        this.focusLevelInfo = "";
    }

    preProcessTableHeaders(header, currID) {
        if (header == "Level") {
            let temp = [];
            let infoIcon = (
                <div>
                    <Button
                        id={currID}
                        aria-label="Level info"
                        className="infoIcon"
                        renderIcon={Information16}
                        kind="ghost"
                        hasIconOnly
                        iconDescription="Level info"
                        tooltipPosition="left"
                        // style={{color:"black", border:"none", verticalAlign:"baseline", minHeight:"30px",
                        //         paddingTop:"0px", paddingLeft:"8px", paddingRight:"8px"}}
                        style={{
                            color: "black",
                            border: "none",
                            verticalAlign: "baseline",
                            minHeight: "30px",
                        }}
                        onClick={() => {
                            this.setState({ LevelsInfoIconModalIsOpen: true });
                            this.focusLevelInfo = currID;
                        }}
                    ></Button>
                </div>
            );
            // let infoModal =

            temp.push(<div style={{ paddingTop: "10px" }}>{header}</div>);
            temp.push(infoIcon);
            // temp.push(infoModal)
            return <div style={{ display: "flex" }}> {temp} </div>;
        } else {
            return header;
        }
    }

    render() {
        const versionHeaders7_3 = [
            {
                header: "Requirement",
                key: "requirement"
            },
            {
                header: "Objective",
                key: "objective"
            },
            {
                header: "Level",
                key: "level"
            },
        ];

        const versionRows7_3 = [
            {
                requirement: "2.4.11 Focus Not Obscured (Minimum)",
                objective: "Keep the focused item visible",
                level: "2"
            },
            {
                requirement: "2.5.7 Dragging Movements",
                objective: "Don’t rely on dragging for user actions",
                level: "2"
            },
            {
                requirement: "2.5.8 Target Size (Minimum)",
                objective: "Make controls easier to activate",
                level: "2"
            },
            {
                requirement: "3.2.6 Consistent Help",
                objective: "Consistently locate user help",
                level: "3"
            },
            {
                requirement: "3.3.7 Redundant Entry",
                objective: "Make it easier for users to complete multi-step processes",
                level: "3"
            },
            {
                requirement: "3.3.8 Accessible Authentication (Minimum)",
                objective: "Make logins possible with less mental effort",
                level: "3"
            },
        ];

        const versionHeaders7_2 = [
            {
                header: "Requirement",
                key: "requirement"
            },
            {
                header: "Objective",
                key: "objective"
            },
            {
                header: "Level",
                key: "level"
            },
        ];

        const versionRows7_2 = [
            {
                requirement: "5.2 Activation of accessibility features",
                objective: "Let users activate accessibility features",
                level: "3"
            },
            {
                requirement: "5.7 Key repeat",
                objective: 'Let user adjust keyboard key "repeat" rates',
                level: "3"
            },
            {
                requirement: "5.8 Double-strike key acceptance",
                objective: 'Let user adjust keyboard key "bounce" accept rates',
                level: "3"
            },
            {
                requirement: "5.9 Simultaneous user actions ",
                objective: 'Let user activate keyboard "sticky keys"',
                level: "3"
            },
            {
                requirement: "7.1.4 Captions characteristics",
                objective: "Ensure video players support presentation characteristics of closed captions",
                level: "3"
            },
            {
                requirement: "7.1.5 Spoken subtitles",
                objective: "Ensure video players support spoken output of available captions",
                level: "3"
            },
        ];

        const versionHeaders7_1 = [
            {
                header: "Requirement",
                key: "requirement"
            },
            {
                header: "Objective",
                key: "objective"
            },
            {
                header: "Level",
                key: "level"
            },
        ];

        const versionRows7_1 = [
            {
                requirement: "1.3.4 Orientation",
                objective: "Don’t lock content to either portrait or landscape presentation mode",
                level: "2"
            },
            {
                requirement: "1.3.5 Identify Input Purpose",
                objective: "Make the meaning of common inputs available via technology",
                level: "3"
            },
            {
                requirement: "1.4.10 Reflow",
                objective: "Ensure content can be enlarged without horizontal scrolling",
                level: "1"
            },
            {
                requirement: "1.4.11 Non-text Contrast",
                objective: "Ensure important visuals meet same minimum contrast as large text",
                level: "2"
            },
            {
                requirement: "1.4.12 Text Spacing",
                objective: "Let users adjust text spacing to make it easier to read ",
                level: "3"
            },
            {
                requirement: "1.4.13 Content on Hover or Focus",
                objective: "Ensure content that appears on hover/focus persists until dismissed",
                level: "2"
            },
            {
                requirement: "2.1.4 Character Key Shortcuts",
                objective: "Ensure custom shortcuts are not accidentally triggered",
                level: "3"
            },
            {
                requirement: "2.5.1 Pointer Gestures",
                objective: "Let users operate touchscreens with a simple gestures",
                level: "2"
            },
            {
                requirement: "2.5.2 Pointer Cancellation",
                objective:
                    "Make operations consistent to avoid accidental activation of controls",
                level: "3"
            },
            {
                requirement: "2.5.3 Label in Name",
                objective:
                    "Make the visual label for controls a trigger for speech activation",
                level: "2"
            },
            {
                requirement: "2.5.4 Motion Actuation",
                objective: "Don’t rely solely on device motion to control page content",
                level: "2"
            },
            {
                requirement: "4.1.3 Status Messages",
                objective:
                    "Let assistive technologies notify users about messages in the content that don’t take focus",
                level: "2"
            },
        ];

        const historyHeaders = [
            {
                header: "Date",
                key: "date"
            },
            {
                header: "Version",
                key: "version"
            },
            {
                header: "Comments",
                key: "comments"
            },
        ];

        const historyRows = [
            {
                date: "06/10/2024",
                version: "7.3",
                comments: "Made version 7.3 the default listed and added an adoption deadline in the introduction."
            },
            {
                date: "11/15/2023",
                version: "7.3",
                comments: "Produced a draft of version 7.3, which includes 6 new WCAG 2.2 criteria."
            },
            {
                date: "11/15/2023",
                version: "7.2",
                comments: "Updated reference to version of EN 301 549 from 3.1.1 to 3.2.1; no requirements changes."
            },
            {
                date: "10/12/2023",
                version: "7.2",
                comments: "Modified 4.1.1 Parsing per WCAG 2.0 and 2.1 errata."
            },
            {
                date: "03/30/2021",
                version: "7.2",
                comments: "Clarified 7.1.2 Captioning Synchronization."
            },
            {
                date: "12/03/2020",
                version: "7.2",
                comments: "Added 6 new requirements from EN 301 549 version 3.1.1:  5.2 Activation of accessibility features, 5.7 Key repeat, 5.8 Double-strike key acceptance, 5.9 Simultaneous user actions, 7.1.4 Captions characteristics, and 7.1.5 Spoken subtitles."
            },
            {
                date: "09/19/2019",
                version: "7.1",
                comments: "Added or modified 1.3.4 Orientation, 1.3.5 Identify Input Purpose, 1.4.11 Non-text Contrast, 1.4.12 Text Spacing, 1.4.13 Content on Hover or Focus, 2.1.4 Character Key Shortcuts,  2.5.1 Pointer Gestures,  2.5.2 Pointer Cancellation, and 2.5.4 Motion Actuation."
            },
            {
                date: "06/23/2019",
                version: "7.1",
                comments: "Added 12 new WCAG 2.1 requirements. Renumbered: 4.1.3 to 4.4.1, 11.3.2.3 to 11.5.2.3, 10.2.39 to 10.5, 10.2.40 to 10.6, 11.6.4 to 11.8.4. Updated impact analysis and change summary docs."
            },
            {
                date: "10/26/2017",
                version: "7.0",
                comments: "Reworded short descriptions for 2.4.1, 2.4.5, 3.2.3, and 3.2.4 to match WCAG."
            },
            {
                date: "07/25/2017",
                version: "7.0",
                comments: "Added 7 new EN 301 549 V2.1.2 requirements, covering ICT with video capabilities."
            },
            {
                date: "07/18/2017",
                version: "7.0",
                comments: "Minor changes to checklist introduction text to reflect official July 18 date of adoption."
            },
            {
                date: "06/13/2017",
                version: "7.0",
                comments: "Reformatted checklist to match IBM’s current v18 publishing standard."
            },
            {
                date: "05/08/2017",
                version: "7.0",
                comments: "New US Revised 508 Standards. New EN 301 549 V1.1.2 requirements. Removed legacy section of 508 requirements that are no longer required."
            },
        ];

        return (
            // Overview heading text removed - but not div and class headerLeftRN
            <React.Fragment>
                <Modal
                    aria-label="Levels information"
                    modalHeading="Pace of completion levels"
                    passiveModal={true}
                    open={this.state.LevelsInfoIconModalIsOpen}
                    onRequestClose={() => {
                        this.setState({ LevelsInfoIconModalIsOpen: false });
                        // FIX #2992 set focus back to the Level info button after the modal closes
                        setTimeout(
                            function () {
                                document.getElementById(this.focusLevelInfo).focus();
                            }.bind(this),
                            100
                        );
                    }}
                >
                    <p>
                        <strong>Level 1:</strong> Essential requirements with high user
                        impact, normally with the least investment. These cover safety,
                        color contrast, text alternatives, navigation, operation, and
                        errors.
                    </p>
                    <p>
                        <strong>Level 2:</strong> Adds to Level 1 the next-most important
                        requirements that enable more users to fully use a product. These
                        include pointer interaction, use of color, text style, auto-playing
                        content, and responsive design.
                    </p>
                    <p>
                        <strong>Level 3:</strong> The full set of IBM accessibility
                        requirements that includes the Web Content Accessibility Guidelines
                        (WCAG), US Revised 508, and European EN 301 549 requirements.
                    </p>
                    <br />
                    <p>
                        <strong>Note:</strong> The{" "}
                        <a
                            href={withPrefix("/toolkit/")}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Equal Access Toolkit
                        </a>{" "}
                        provides guidance only on WCAG requirements. It divides tasks and
                        considerations by role involved in each phase into these three
                        levels of progression for web applications.
                    </p>
                </Modal>

                <div className="headerLeftRN" style={{ marginBottom: "48px" }}>
                    <div className="bx--row dynamicHeaderTopBotRN">
                        <div className="bx--col-lg-3 bx--col-sm-4">
                            {/* <div className="bx--col-lg-3 bx--col-sm-4 bx--type-expressive-heading-02 dynamicHeaderBotRN"> */}
                            {/* Overview */}
                        </div>
                        <div className="bx--col-lg-6 bx--col-sm-4">
                            <p className="ReleaseNotesOverviewQuote">
                                IBM’s accessibility requirements are updated regularly to
                                address current worldwide standards and regulations in a unified
                                list. Version 7.2 is the current version that incorporates{" "}
                                <a href="https://www.w3.org/TR/WCAG21/" target="_blank">
                                    WCAG 2.1
                                </a>
                                <span>, </span>
                                <a href="https://www.access-board.gov/ict/" target="_blank">
                                    US 508
                                </a>, and{" "}
                                <a
                                    href="https://www.etsi.org/deliver/etsi_en/301500_301599/301549/03.02.01_60/en_301549v030201p.pdf"
                                    target="_blank"
                                >
                                    EN 301 549 V3.2.1
                                </a>{" "}
                                standards. Version 7.3 incorporates new <a href="https://www.w3.org/TR/WCAG22/" target="_blank">WCAG 2.2</a> requirements, and must be adopted by teams as of 1 October 2024.
                            </p>
                            <p className="ReleaseNotesOverviewQuote">
                                The tables show the new requirements introduced in each version
                                and their{" "}
                                <a
                                    href={withPrefix("/toolkit/plan/overview/#pace-of-completion")}
                                    target="_blank"
                                >
                                    pace of completion
                                </a>{" "}
                                level as defined in the{" "}
                                <a href="https://www.ibm.com/able/toolkit/" target="_blank">
                                    Equal Access Toolkit
                                </a>.
                            </p>
                            <p className="ReleaseNotesOverviewQuote">
                                Meeting the requirements helps ensure your technology is usable
                                by persons who have disabilities and works with their assistive
                                technologies. IBM teams, see{" "}
                                <a
                                    href="https://pages.github.ibm.com/IBMa/able/Accessibility_requirements/Understanding_CI_162/"
                                    target="_blank"
                                >
                                    Understanding Corporate Instruction CI-162
                                </a>{" "}
                                for more information.
                            </p>
                        </div>
                    </div>
                    <Row className="resource-card-group">
                        <div className="bx--col-lg-3 bx--col-sm-4 dynamicHeaderBotRN"></div>
                        <div
                            className="bx--col-lg-8 bx--col-sm-6"
                            style={{ paddingLeft: 0 }}
                        >
                            <div style={{ overflowX: "auto" }}>
                                <h2
                                    style={{
                                        paddingLeft: "12px",
                                        paddingTop: "32px",
                                        paddingBottom: "24px",
                                    }}
                                    id="table3_summary"
                                    className="bx--type-expressive-heading-02"
                                >
                                    New requirements for version 7.3
                                </h2>
                                <DataTable
                                    rows={versionRows7_3.map((row) => {
                                        row.id = row.requirement;
                                        return row;
                                    })}
                                    headers={versionHeaders7_3}
                                    render={({
                                        rows,
                                        headers,
                                        getHeaderProps,
                                        getTableProps,
                                    }) => (
                                        <Table
                                            {...getTableProps()}
                                            aria-describedby="table3_summary"
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    {headers.map((header) => (
                                                        <TableHeader {...getHeaderProps({ header })}>
                                                            {this.preProcessTableHeaders(
                                                                header.header,
                                                                "info-button-pace-3"
                                                            )}
                                                        </TableHeader>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rows.map((row) => (
                                                    <TableRow key={row.id}>
                                                        {row.cells.map((cell, index) => (
                                                            <TableCell
                                                                style={{
                                                                    verticalAlign: "top",
                                                                    paddingTop: "12px",
                                                                    paddingBottom: "12px",
                                                                }}
                                                                key={cell.id}
                                                            >
                                                                {index === 0 ? (
                                                                    <a
                                                                        href={withPrefix(
                                                                            "/requirements/requirements/?version=7_3#" +
                                                                            cell.value
                                                                                .split(" ")[0]
                                                                                .replace(/[ .]/g, "_")
                                                                        )}
                                                                    >
                                                                        {cell.value}
                                                                    </a>
                                                                ) : (
                                                                    cell.value
                                                                )}
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    )}
                                />
                            </div>
                        </div>
                    </Row>
                    <Row className="resource-card-group">
                        <div className="bx--col-lg-3 bx--col-sm-4 dynamicHeaderBotRN"></div>
                        <div
                            className="bx--col-lg-8 bx--col-sm-6"
                            style={{ paddingLeft: 0 }}
                        >
                            <div style={{ overflowX: "auto" }}>
                                <h2
                                    style={{
                                        paddingLeft: "12px",
                                        paddingTop: "32px",
                                        paddingBottom: "24px",
                                    }}
                                    id="table2_summary"
                                    className="bx--type-expressive-heading-02"
                                >
                                    New requirements for version 7.2
                                </h2>
                                <DataTable
                                    rows={versionRows7_2.map((row) => {
                                        row.id = row.requirement;
                                        return row;
                                    })}
                                    headers={versionHeaders7_2}
                                    render={({
                                        rows,
                                        headers,
                                        getHeaderProps,
                                        getTableProps,
                                    }) => (
                                        <Table
                                            {...getTableProps()}
                                            aria-describedby="table2_summary"
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    {headers.map((header) => (
                                                        <TableHeader {...getHeaderProps({ header })}>
                                                            {this.preProcessTableHeaders(
                                                                header.header,
                                                                "info-button-pace-1"
                                                            )}
                                                        </TableHeader>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rows.map((row) => (
                                                    <TableRow key={row.id}>
                                                        {row.cells.map((cell, index) => (
                                                            <TableCell
                                                                style={{
                                                                    verticalAlign: "top",
                                                                    paddingTop: "12px",
                                                                    paddingBottom: "12px",
                                                                }}
                                                                key={cell.id}
                                                            >
                                                                {index === 0 ? (
                                                                    <a
                                                                        href={withPrefix(
                                                                            "/requirements/requirements/?version=7_2#" +
                                                                            cell.value
                                                                                .split(" ")[0]
                                                                                .replace(/[ .]/g, "_")
                                                                        )}
                                                                    >
                                                                        {cell.value}
                                                                    </a>
                                                                ) : (
                                                                    cell.value
                                                                )}
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    )}
                                />
                            </div>
                        </div>
                    </Row>
                    <Row className="resource-card-group dynamicHeaderMarginRN">
                        <div className="bx--col-lg-3 bx--col-sm-4 bx--type-expressive-heading-02 dynamicHeaderBotRN"></div>
                        <div
                            className="bx--col-lg-8 bx--col-sm-6"
                            style={{ paddingLeft: 0 }}
                        >
                            <div style={{ overflowX: "auto" }}>
                                <h2
                                    style={{
                                        paddingLeft: "12px",
                                        paddingTop: "0px",
                                        paddingBottom: "24px",
                                    }}
                                    id="table1_summary"
                                    className="bx--type-expressive-heading-02"
                                >
                                    New requirements for version 7.1
                                </h2>
                                <DataTable
                                    rows={versionRows7_1.map((row) => {
                                        row.id = row.requirement;
                                        return row;
                                    })}
                                    headers={versionHeaders7_1}
                                    render={({
                                        rows,
                                        headers,
                                        getHeaderProps,
                                        getTableProps,
                                    }) => (
                                        <Table
                                            {...getTableProps()}
                                            aria-describedby="table1_summary"
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    {headers.map((header) => (
                                                        <TableHeader {...getHeaderProps({ header })}>
                                                            {this.preProcessTableHeaders(
                                                                header.header,
                                                                "info-button-pace-2"
                                                            )}
                                                        </TableHeader>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rows.map((row) => (
                                                    <TableRow key={row.id}>
                                                        {row.cells.map((cell, index) => (
                                                            <TableCell
                                                                style={{
                                                                    verticalAlign: "top",
                                                                    paddingTop: "12px",
                                                                    paddingBottom: "12px",
                                                                }}
                                                                key={cell.id}
                                                            >
                                                                {index === 0 ? (
                                                                    <a
                                                                        href={withPrefix(
                                                                            "/requirements/requirements/?version=7_1#" +
                                                                            cell.value
                                                                                .split(" ")[0]
                                                                                .replace(/[ .]/g, "_")
                                                                        )}
                                                                    >
                                                                        {cell.value}
                                                                    </a>
                                                                ) : (
                                                                    cell.value
                                                                )}
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    )}
                                />
                            </div>
                        </div>
                    </Row>
                    <Row className="resource-card-group dynamicHeaderMarginRN">
                        <div className="bx--col-lg-3 bx--col-sm-4 bx--type-expressive-heading-02 dynamicHeaderBotRN"></div>
                        <div
                            className="bx--col-lg-8 bx--col-sm-6"
                            style={{ paddingLeft: 0 }}
                        >
                            <div style={{ overflowX: "auto" }}>
                                <h2
                                    style={{
                                        paddingLeft: "12px",
                                        paddingTop: "0px",
                                        paddingBottom: "24px",
                                    }}
                                    id="table4_summary"
                                    className="bx--type-expressive-heading-02"
                                >
                                    Change history
                                </h2>
                                <DataTable
                                    rows={historyRows.map(row => {
                                        row.id = row.date + " " + row.version;
                                        return row;
                                    })}
                                    headers={historyHeaders}
                                    render={({
                                        rows,
                                        headers,
                                        getHeaderProps,
                                        getTableProps,
                                    }) => (
                                        <Table
                                            {...getTableProps()}
                                            aria-describedby="table4_summary"
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    <TableHeader>Date</TableHeader>
                                                    <TableHeader>Version</TableHeader>
                                                    <TableHeader>Comments</TableHeader>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>06/10/2024</TableCell>
                                                    <TableCell>
                                                        <a href="https://www.ibm.com/able/requirements/requirements/?version=7_3" target="_blank">7.3</a>
                                                    </TableCell>
                                                    <TableCell>
                                                        Made version 7.3 the default listed and added an adoption deadline in the introduction. Removed requirements <a href={withPrefix("/requirements/requirements/?version=7_2#4_1_1")}>4.1.1 Parsing</a> from 7.3 and 4.1.4 Accessibility-supported Technologies Only (IBM requirement) from all versions.
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>11/15/2023</TableCell>
                                                    <TableCell>
                                                        <a href="https://www.ibm.com/able/requirements/requirements/?version=7_3" target="_blank">7.3</a>
                                                    </TableCell>
                                                    <TableCell>
                                                        Produced a draft of version 7.3, which includes 6 new WCAG 2.2 criteria.
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>11/15/2023</TableCell>
                                                    <TableCell>
                                                        <a href="https://www.ibm.com/able/requirements/requirements/?version=7_2" target="_blank">7.2</a>
                                                    </TableCell>
                                                    <TableCell>
                                                        Updated reference to version of EN 301 549 from 3.1.1 to 3.2.1; no requirements changes.
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>10/12/2023</TableCell>
                                                    <TableCell>
                                                        <a href="https://www.ibm.com/able/requirements/requirements/?version=7_2" target="_blank">7.2</a>
                                                    </TableCell>
                                                    <TableCell>
                                                        Modified: <a href={withPrefix("/requirements/requirements/?version=7_2#4_1_1")}>4.1.1 Parsing</a> per
                                                        WCAG 2.0 and 2.1 errata.
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>03/20/2021</TableCell>
                                                    <TableCell>
                                                        <a href="https://www.ibm.com/able/requirements/requirements/?version=7_2" target="_blank">7.2</a>
                                                    </TableCell>
                                                    <TableCell>
                                                        Clarified: <a href={withPrefix("/requirements/requirements/?version=7_2#7_1_2")}>7.1.2 Captioning synchronization</a>.
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>12/03/2020</TableCell>
                                                    <TableCell>
                                                        <a href="https://www.ibm.com/able/requirements/requirements/?version=7_2" target="_blank">7.2</a>
                                                    </TableCell>
                                                    <TableCell>
                                                        <strong>
                                                            <a href="https://www.ibm.com/able/requirements/requirements/?version=7_2" target="_blank" rel="noopener noreferrer">
                                                                Version 7.2
                                                            </a>
                                                        </strong> includes <a href="https://www.etsi.org/deliver/etsi_en/301500_301599/301549/03.01.01_60/en_301549v030101p.pdf" target="_blank" rel="noopener noreferrer">
                                                            EN 301 549 V3.1.1
                                                        </a> (updated), <a href="https://www.w3.org/TR/WCAG21/" target="_blank" rel="noopener noreferrer">
                                                            WCAG 2.1
                                                        </a>, and <a href="https://www.access-board.gov/ict/" target="_blank" rel="noopener noreferrer">
                                                            US Revised 508
                                                        </a> standards. <br />
                                                        Added 6 new requirements: <a href={withPrefix("/requirements/requirements/?version=7_2#5_2")}>
                                                            5.2 Activation of accessibilityfeatures
                                                        </a>, <a href={withPrefix("/requirements/requirements/?version=7_2#5_7")}>
                                                            5.7 Key repeat
                                                        </a>, <a href={withPrefix("/requirements/requirements/?version=7_2#5_8")}>
                                                            5.8 Double-strike key acceptance
                                                        </a>, <a href={withPrefix("/requirements/requirements/?version=7_2#5_9")}>
                                                            5.9 Simultaneous user actions
                                                        </a>, <a href={withPrefix("/requirements/requirements/?version=7_2#7_1_4")}>
                                                            7.1.4 Captions characteristics
                                                        </a>, and <a href={withPrefix("/requirements/requirements/?version=7_2#7_1_5")}>
                                                            7.1.5 Spoken subtitles
                                                        </a>.
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>09/19/2019</TableCell>
                                                    <TableCell>
                                                        <a href="https://www.ibm.com/able/requirements/requirements/?version=7_1" target="_blank">7.1</a>
                                                    </TableCell>
                                                    <TableCell>
                                                        Added or modified: <a href={withPrefix("/requirements/requirements/?version=7_1#1_3_4")}>
                                                            1.3.4 Orientation
                                                        </a>, <a href={withPrefix("/requirements/requirements/?version=7_1#1_3_4")}>
                                                            1.3.5 Identify Input Purpose
                                                        </a>, <a href={withPrefix("/requirements/requirements/?version=7_1#1_4_11")}>
                                                            1.4.11 Non-text Contrast
                                                        </a>, <a href={withPrefix("/requirements/requirements/?version=7_1#1_4_12")}>
                                                            1.4.12 Text Spacing
                                                        </a>, <a href={withPrefix("/requirements/requirements/?version=7_1#1_4_13")}>
                                                            1.4.13 Content on Hover or focus
                                                        </a>, <a href={withPrefix("/requirements/requirements/?version=7_1#2_1_4")}>
                                                            2.1.4 Character Key Shortcuts
                                                        </a>, <a href={withPrefix("/requirements/requirements/?version=7_1#2_5_1")}>
                                                            2.5.1 Pointer Gestures
                                                        </a>, <a href={withPrefix("/requirements/requirements/?version=7_1#2_5_2")}>
                                                            2.5.2 Pointer Cancellation
                                                        </a>, and <a href={withPrefix("/requirements/requirements/#2_5_4")}>
                                                            2.5.4 Motion Actuation
                                                        </a>.
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>06/23/2019</TableCell>
                                                    <TableCell>
                                                        <a href="https://www.ibm.com/able/requirements/requirements/?version=7_1" target="_blank">7.1</a>
                                                    </TableCell>
                                                    <TableCell>
                                                        <strong>
                                                            <a href="https://www.ibm.com/able/requirements/requirements/?version=7_1" target="_blank" rel="noopener noreferrer">
                                                                Version 7.1
                                                            </a>
                                                        </strong> includes <a href="https://www.etsi.org/deliver/etsi_en/301500_301599/301549/02.01.02_60/en_301549v020102p.pdf" target="_blank" rel="noopener noreferrer">
                                                            EN 301 549 V2.1.2
                                                        </a> (updated), <a href="https://www.w3.org/TR/WCAG21/" target="_blank" rel="noopener noreferrer">
                                                            WCAG 2.1
                                                        </a> (updated), and <a href="https://www.access-board.gov/ict/" target="_blank" rel="noopener noreferrer">
                                                            US Revised 508
                                                        </a> standards. <br />
                                                        Added 12 new WCAG 2.1 requirements. Renumbered: 4.1.3 to 4.4.1, 11.3.2.3 to
                                                        11.5.2.3, 10.2.39 to 10.5, 10.2.40 to 10.6, 11.6.4 to 11.8.4. Updated impact
                                                        analysis and change summary docs.
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>10/26/2017</TableCell>
                                                    <TableCell>
                                                        7.0
                                                    </TableCell>
                                                    <TableCell>
                                                        Reworded short descriptions for 2.4.1, 2.4.5, 3.2.3, and 3.2.4 to match WCAG.
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>07/25/2017</TableCell>
                                                    <TableCell>
                                                        7.0
                                                    </TableCell>
                                                    <TableCell>
                                                        Added 7 new EN 301 549 V2.1.2 requirements, covering ICT with video capabilities.
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>07/18/2017</TableCell>
                                                    <TableCell>
                                                        7.0
                                                    </TableCell>
                                                    <TableCell>
                                                        Minor changes to checklist introduction text to reflect official July 18 date of adoption.
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>06/13/2017</TableCell>
                                                    <TableCell>
                                                        7.0
                                                    </TableCell>
                                                    <TableCell>
                                                        Reformatted checklist to match IBM’s current v18 publishing standard.
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>05/08/2017</TableCell>
                                                    <TableCell>
                                                        7.0
                                                    </TableCell>
                                                    <TableCell>
                                                        Version 7.0 includes <a href="https://www.etsi.org/deliver/etsi_en/301500_301599/301549/01.01.02_60/en_301549v010102p.pdf" target="_blank" rel="noopener noreferrer">
                                                            EN 301 549 V1.1.2
                                                        </a>(new), <a href="https://www.w3.org/TR/WCAG20/" target="_blank" rel="noopener noreferrer">
                                                            WCAG 2.0
                                                        </a>, and <a href="https://www.access-board.gov/ict/" target="_blank" rel="noopener noreferrer">
                                                            US Revised 508
                                                        </a> (updated) standards. <br />
                                                        Removed legacy section of 508 requirements that are no longer required.
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    )}
                                />
                            </div>
                        </div>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

export default ChecklistReleaseNotes;
