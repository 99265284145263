import React, { Component } from 'react'
import { TkAccordionItemCL } from "../toolkit/TkAccordionItemCL";
import { Accordion } from '../../gatsby-theme-carbon/components/Accordion';
import { Button, Dropdown, Checkbox, RadioButton, RadioButtonGroup, Modal, Form } from 'carbon-components-react';
import "../../pages/toolkit/topics.scss"
import ReqSwitcher from "./reqSwitcher"
import { Information16 } from '@carbon/icons-react';
import { withPrefix } from "gatsby"
import Link from 'carbon-components-react/lib/components/Link';

import { reqData } from "./reqData.js"

import Copy16 from "@carbon/icons-react/lib/copy/16";

import './checklistRequirements.scss'

// Import all mdx file

class ChecklistRequirements extends Component {
    VERSION_KEYS = ["v7_1", "v7_2", "v7_3"];
    TYPE_KEYS = ["web", "software", "doc_web", "doc_nonweb", "authoring"];
    STD_KEYS = ["wcag", "us_508", "en_301_549"];

    state = {
        expand: false,
        show: false,
        modalVersionOpen: false,
        modalTechOpen: false,
        modalStandardsOpen: false,
        modalLevelsOpen: false,
        reqFilters: {
            v7_1: false,
            v7_2: false,
            v7_3: true,
            web: true,
            software: true,
            doc_web: true,
            doc_nonweb: true,
            authoring: true,
            wcag: true,
            us_508: true,
            en_301_549: true,
            level1: true,
            level2: true,
            level3: true
        }
    }

    async componentDidMount() {
        let reqFilters = JSON.parse(JSON.stringify(this.state.reqFilters));
        const search = typeof window !== 'undefined' && window.location.search || "?";
        let vmatch = /version=v?([\d._]+)/.exec(search);
        if (vmatch) {
            const version = "v" + (vmatch[1].replace(/\./g, "_"));
            if (version in reqFilters) {
                for (const key of this.VERSION_KEYS) {
                    reqFilters[key] = false;
                }
                reqFilters[version] = true;
            }
        }
        this.setState({ show: true, reqFilters });
    }

    // the job of this function is to determine for the selected filters,
    // i.e., have value true in the filters state, if the requirement data
    // matches the filter data such that the requirement should be shown
    showRequirements() {

        let showCounter = 0;
        let version = this.VERSION_KEYS.find(key => this.state.reqFilters[key]);

        if (this.state.show === true) {
            reqData.map((data, key) => {
                data.show = false; // clear shows
            });
        }

        reqData.map((data, key) => {
            const matchesVersion = this.VERSION_KEYS.filter(key => this.state.reqFilters[key] && data[key]).length > 0;
            const matchesType = this.TYPE_KEYS.filter(key => this.state.reqFilters[key] && data[key]).length > 0;
            const matchesSTD = this.STD_KEYS.filter(key => this.state.reqFilters[key] && data[key]).length > 0;
            const matchesLVL = (this.state.reqFilters.level1 && data.level1)
                || (this.state.reqFilters.level2 && (data.level1 || data.level2))
                || (this.state.reqFilters.level3 && (data.level1 || data.level2 || data.level3));
            data.show = matchesVersion && matchesType && matchesSTD && matchesLVL;

            if (data.show === true) {
                ++showCounter;
            }
        });
        // [ showing, version key, reqs in that version ]
        return [showCounter, version, reqData.filter(item => item[version]).length];
    }

    // The job of the next four functions is to each fill in their
    // part of the filters state (this.state.filters) by ascertaining
    // whether the filter is selected (value 1) or not (value 0)

    versionDropdown = (selectedItem) => {
        let reqFilters = JSON.parse(JSON.stringify(this.state.reqFilters));
        for (const key of this.VERSION_KEYS) {
            reqFilters[key] = false;
        }
        reqFilters[selectedItem.id] = true;
        this.setState({ reqFilters });
    }

    filtCheckbox = (value, type) => {
        // value: [true,false], type: ["web", "software", "doc_web", "doc_nonweb", "authoring"]
        let reqFilters = JSON.parse(JSON.stringify(this.state.reqFilters));
        reqFilters[type] = value;
        this.setState({ reqFilters });
    }

    levelRadioButton = (value) => {
        let reqFilters = JSON.parse(JSON.stringify(this.state.reqFilters));
        reqFilters.level1 = reqFilters.level2 = reqFilters.level3 = false;
        reqFilters[value] = true;
        this.setState({ reqFilters });
    }

    expandCollapse = () => {
        let open = false;

        if (this.state.expand === true) {
            open = false;
        } else {
            open = true;
        }
        reqData.map((data, key) => {
            data.open = open;
        });
        this.setState({ expand: open });
    }

    render() {
        // Dropdown menu items
        const items = [
            {
                id: 'v7_1',
                text: '7.1'
            },
            {
                id: 'v7_2',
                text: '7.2 (Current)'
            },
            {
                id: 'v7_3',
                text: '7.3'
            }
        ];

        // [ showing, version key, reqs in that version ]
        const [count, versionKey, verCount] = this.showRequirements();
        let WCAGLabel = "WCAG 2.2";
        if (this.state.reqFilters.v7_1 || this.state.reqFilters.v7_2) {
            WCAGLabel = "WCAG 2.1";
        }

        return (
            // Overview heading text removed - but not div and class
            <React.Fragment>

                <div className="requirementsMain" style={{ marginBottom: "48px" }} >
                    <div id={this.props.eventName} className="bx--row dynamicHeaderTopBot">
                        <div className="bx--col-lg-3 bx--col-sm-4">
                            {/* <div className="bx--col-lg-3 bx--col-sm-4 bx--type-expressive-heading-02 dynamicHeaderBot"> */}
                            {/* Overview */}
                        </div>
                        <div className="bx--col-lg-6 bx--col-sm-4">
                            <p className="eventTitleCardBody">
                                This page lists the accessibility requirements that need to be met for several standards and regulations.
                                <br /><br />
                                IBM teams must adopt version 7.3 of the requirements by 1 October 2024. It includes six new Web Content Accessibility Guidelines (WCAG) 2.2 Level A and AA success criteria. These are identified with “*WCAG 2.2”. Version 7.2 can continue to be used until that time; however, teams are encouraged to adopt 7.3 proactively. IBM has also published an article on using <a href="https://medium.com/design-ibm/assessing-components-and-patterns-for-wcag-2-2-47ee3f0f468e" target="_blank">decision trees</a> to check existing components and patterns against these new criteria.
                                <br /><br />
                                For IBM internal use, see additional requirements for <a href="https://pages.github.ibm.com/IBMa/able/Accessibility_requirements/Hardware_Requirements" target="_blank" rel="noopener noreferrer">hardware</a>, <a href="https://pages.github.ibm.com/IBMa/able/Accessibility_requirements/Closed_Functionality_Requirements" target="_blank" rel="noopener noreferrer">closed functionality devices</a>, and <a href="https://pages.github.ibm.com/IBMa/able/Accessibility_requirements/Two-Way_Communications" target="_blank" rel="noopener noreferrer">two-way voice communications</a>, as well as detailed <a href="https://pages.github.ibm.com/IBMa/able/Test/verify/" target="_blank" rel="noopener noreferrer">accessibility verification test (AVT)</a> guidance.
                                <br /><br />

                            </p>
                        </div>
                    </div>

                    <div className="bx--row">
                        <div className="bx--col-md-8 bx--offset-lg-3">{/* Version drop-down */}
                            <div style={{ fontSize: "12px", lineHeight: "52px", textAlign: "left", height: "28px", marginTop: "12px" }}>
                                <span style={{ verticalAlign: "3px" }}>Version</span>
                                <Button
                                    renderIcon={Information16}
                                    kind="ghost"
                                    hasIconOnly iconDescription="Version info" tooltipPosition="top"
                                    style={{
                                        color: "black", border: "none", verticalAlign: "baseline", minHeight: "28px",
                                        paddingTop: "8px", paddingLeft: "8px", paddingRight: "8px"
                                    }}
                                    onClick={(() => {
                                        this.setState({ modalVersionOpen: true });
                                    })}>
                                </Button>
                            </div>
                            <div style={{ marginTop: "18px" }}>
                                <div style={{ height: "48px", width: "100%", padding: "0px" }} class="bx--col-lg-8">
                                    <Dropdown
                                        id="downshift-0-label"
                                        light
                                        //titleText="Version"
                                        items={items}
                                        itemToString={(item) => (item ? item.text : '')}
                                        ariaLabel="Requirements version menu"
                                        // initialSelectedItem={values[1] === "v7.1" ? items[0] : items[1]}
                                        selectedItem={items.find(item => item.id === versionKey)}
                                        onChange={({ selectedItem }) => this.versionDropdown(selectedItem)}
                                    />
                                </div>
                            </div>
                            <Modal
                                aria-label="Version information"
                                modalHeading="Select a version"
                                passiveModal={true}
                                open={this.state.modalVersionOpen}
                                onRequestClose={(() => {
                                    this.setState({ modalVersionOpen: false });
                                })}
                            >
                                <p>IBM Accessibility requirements are updated to stay current with worldwide accessibility standards and regulations.</p>
                                <br />
                                <p><strong>Version 7.3</strong> is the preferred version of the requirements, which includes newer WCAG 2.2 success criteria. It must be adopted by 1 October 2024.</p>
                                <br />
                                <p><strong>Version 7.2</strong> is the current version of the requirements and officially replaced Version 7.1 on 1 July 2021.</p>
                                <br />
                                <p><strong>Version 7.1</strong> is for projects in development prior to 1 July 2021. It officially replaced Version 7.0 on 1 January 2020.</p>
                                <br />
                                <p>See <a href={withPrefix("/requirements/release-notes")} target="_blank" rel="noopener noreferrer">Release notes</a> for more details.</p>
                            </Modal>

                            <div style={{ marginTop: "8px", marginBottom: "64px" }}> {/*  BEGIN USER SELECTION BLOCK */}
                                <div className="bx--row">{/* Technology checkbox selections */}
                                    <div style={{ paddingRight: "33px", paddingLeft: "10px" }}>
                                        <div style={{ marginTop: "8px" }}>
                                            <fieldset className={`settings--fieldset`}>
                                                <legend style={{ marginBottom: "8px" }}>
                                                    <div style={{ fontSize: "12px", lineHeight: "48px", textAlign: "left", height: "28px", marginTop: "6px" }}>
                                                        <span style={{ verticalAlign: "3px" }}>Technology</span>
                                                        <Button
                                                            renderIcon={Information16}
                                                            kind="ghost"
                                                            hasIconOnly iconDescription="Technology info" tooltipPosition="top"
                                                            style={{
                                                                color: "black", border: "none", verticalAlign: "baseline", minHeight: "28px",
                                                                paddingTop: "8px", paddingLeft: "8px", paddingRight: "8px"
                                                            }}
                                                            onClick={(() => {
                                                                this.setState({ modalTechOpen: true });
                                                            })}>
                                                        </Button>
                                                    </div></legend>
                                                <Checkbox defaultChecked labelText={`Web`} id="web" onChange={(value, type) => this.filtCheckbox(value, type)} />
                                                <Checkbox defaultChecked labelText={`Non-web software`} id="software" onChange={(value, type) => this.filtCheckbox(value, type)} />
                                                <Checkbox defaultChecked labelText={`Web documents`} id="doc_web" onChange={(value, type) => this.filtCheckbox(value, type)} />
                                                <Checkbox defaultChecked labelText={`Non-web documents`} id="doc_nonweb" onChange={(value, type) => this.filtCheckbox(value, type)} />
                                                <Checkbox defaultChecked labelText={`Authoring tools`} id="authoring" onChange={(value, type) => this.filtCheckbox(value, type)} />
                                            </fieldset>
                                        </div>
                                        <Modal
                                            aria-label="Technology information"
                                            modalHeading="Filter by technology"
                                            passiveModal={true}
                                            open={this.state.modalTechOpen}
                                            onRequestClose={(() => {
                                                this.setState({ modalTechOpen: false });
                                            })}
                                        >
                                            <p>A key aspect of all digital technologies --  whether web, software, or documentation -- is that the user interface needs
                                                to conform to applicable criteria in the IBM accessibility requirements.  Use these filters to narrow
                                                down and understand the list applicable to your project.
                                                The resulting filtered set of requirements include criteria from WCAG,
                                                US Revised 508 Standards, and European EN 301 549 standards:</p>
                                            <br />

                                            <p><strong>Web:</strong> Use this filter for a web site or application that runs in a browser.
                                            </p>
                                            <br />
                                            <p><strong>Non-web software:</strong> Use this filter for all software that is not web-based, such as native
                                                mobile apps, Windows® and MacOS® desktop, and command-line software.
                                            </p>
                                            <br />
                                            <p><strong>Web documents:</strong> Use this filter for support documentation, learning, videos,
                                                and marketing materials that run in a browser, such as HTML and EPUB.
                                            </p>
                                            <br />
                                            <p><strong>Non-web documents:</strong> Use this filter for support documentation, learning,
                                                and marketing materials that are not integrated into a web-based application or are provided separately,
                                                such as PDF, DOCX, PPTX, and XLSX formatted attachments.
                                            </p>
                                            <br />
                                            <p><strong>Authoring tools:</strong> Use this filter to view specific requirements that cover both the end-user
                                                features of an application that promote the generation of output, and the accessibility of the output
                                                itself. Authoring tools include features in software that “authors” (developers, writers, etc.) use to
                                                to create or modify content.  See <a href="https://www.w3.org/WAI/standards-guidelines/atag/" target="_blank" rel="noopener noreferrer">Authoring Tools Accessibility Guidelines</a> for more information.
                                            </p>
                                        </Modal>
                                    </div>
                                    <div style={{ paddingRight: "33px" }}>{/* Standards checkboxes */}
                                        <div style={{ marginTop: "8px" }}>
                                            <fieldset className={`settings--fieldset`}>
                                                <legend style={{ marginBottom: "8px" }}>
                                                    <div style={{ fontSize: "12px", lineHeight: "48px", textAlign: "left", height: "28px", marginTop: "8px" }}>
                                                        <span style={{ verticalAlign: "3px" }}>Standards</span>
                                                        <Button
                                                            renderIcon={Information16}
                                                            kind="ghost"
                                                            hasIconOnly iconDescription="Standards info" tooltipPosition="top"
                                                            style={{
                                                                color: "black", border: "none", verticalAlign: "baseline", minHeight: "28px",
                                                                paddingTop: "8px", paddingLeft: "8px", paddingRight: "8px"
                                                            }}
                                                            onClick={(() => {
                                                                this.setState({ modalStandardsOpen: true });
                                                            })}>
                                                        </Button>
                                                    </div>
                                                </legend>
                                                <Checkbox defaultChecked labelText={WCAGLabel} id="wcag" onChange={(value, type) => this.filtCheckbox(value, type)} />
                                                <Checkbox defaultChecked labelText={`US 508`} id="us_508" onChange={(value, standard) => this.filtCheckbox(value, standard)} />
                                                <Checkbox defaultChecked labelText={`EN 301 549`} id="en_301_549" onChange={(value, standard) => this.filtCheckbox(value, standard)} />
                                            </fieldset>
                                        </div>
                                        <Modal
                                            aria-label="Standards information"
                                            modalHeading="Filter by standard"
                                            passiveModal={true}
                                            open={this.state.modalStandardsOpen}
                                            onRequestClose={(() => {
                                                this.setState({ modalStandardsOpen: false });
                                            })}
                                        >
                                            <p><strong>WCAG 2.x:</strong>&nbsp;
                                                The Web Content Accessibility Guidelines (WCAG) include multiple releases:
                                                <br />
                                                <ul>
                                                    <li><a href="https://www.w3.org/TR/WCAG21/" target="_blank" rel="noopener noreferrer">WCAG 2.1</a>&nbsp;
                                                        (05 June 2018) extended <a href="https://www.w3.org/TR/WCAG20/" target="_blank" rel="noopener noreferrer">WCAG 2.0</a> (11 December 2008). Many regulations reference WCAG 2.1 Level A and AA
                                                        Success Criteria.</li>
                                                    <br />
                                                    <li><a href="https://www.w3.org/TR/WCAG22/" target="_blank" rel="noopener noreferrer">WCAG 2.2</a> (05 October 2023) added six new level A and AA Success Criteria, shown in the 7.3 version of the checklist, and will be referenced by future regulations.</li></ul>
                                            </p>
                                            <br />
                                            <p><strong>US 508:</strong>&nbsp;<a href="https://www.access-board.gov/ict/" target="_blank" rel="noopener noreferrer">US Revised 508 Standards</a> provide requirements for information and communication technology (ICT) in the federal sector.
                                            </p>
                                            <br />
                                            <p><strong>EN 301 549:</strong>&nbsp;<a href="https://www.etsi.org/deliver/etsi_en/301500_301599/301549/03.02.01_60/en_301549v030201p.pdf" target="_blank" rel="noopener noreferrer">Accessibility requirements for ICT products and services, V3.2.1</a> sets requirements to meet European accessibility regulations.
                                            </p>
                                            <br />
                                            <p><strong>Note for IBM teams:</strong>&nbsp;The IBM internal-use-only <a href="https://able.ibm.com/acs/" target="_blank" rel="noopener noreferrer">accessibility compliance system</a> reports status for US 508 and EN 301 549.
                                                Although the US Revised 508 Standards only require conformance to WCAG 2.0 and
                                                the European EN 301 549 standard only requires WCAG 2.1, some clients are requesting 2.2 and some jurisdictions are adopting it into regulation. Therefore, IBM requires WCAG 2.2 as of 1 October 2024.
                                                All applicable requirements will need to be met to report full conformance.
                                            </p>
                                        </Modal>
                                    </div>
                                    <div>{/* Levels radio boxes */}
                                        <div style={{ marginTop: "8px" }}>
                                            <Form>
                                                <fieldset className={`settings--fieldset`}>
                                                    <legend style={{ marginBottom: "8px" }}>
                                                        <div style={{ fontSize: "12px", lineHeight: "48px", textAlign: "left", height: "28px", marginTop: "8px" }}>
                                                            <span style={{ verticalAlign: "3px" }}>Level to achieve</span>
                                                            <Button
                                                                renderIcon={Information16}
                                                                kind="ghost"
                                                                hasIconOnly iconDescription="Levels info" tooltipPosition="top"
                                                                style={{
                                                                    color: "black", border: "none", verticalAlign: "baseline", minHeight: "28px",
                                                                    paddingTop: "8px", paddingLeft: "8px", paddingRight: "8px"
                                                                }}
                                                                onClick={(() => {
                                                                    this.setState({ modalLevelsOpen: true });
                                                                })}>
                                                            </Button>
                                                        </div>
                                                    </legend>
                                                    <RadioButtonGroup
                                                        onChange={(value) => this.levelRadioButton(value)}
                                                        name='type'
                                                        orientation='vertical'
                                                        defaultSelected='level3'
                                                    >
                                                        <RadioButton value="level1" id="level1" name='level1' labelText='Level 1' />
                                                        <RadioButton value="level2" id="level2" name='level2' labelText='Level 2' />
                                                        <RadioButton value="level3" id="level3" name='level3' labelText='Level 3' />
                                                    </RadioButtonGroup>
                                                </fieldset>
                                            </Form>
                                        </div>
                                        <Modal
                                            aria-label="Select a level to achieve"
                                            modalHeading="Select a level to achieve"
                                            passiveModal={true}
                                            open={this.state.modalLevelsOpen}
                                            onRequestClose={(() => {
                                                this.setState({ modalLevelsOpen: false });
                                            })}
                                        >
                                            <p><strong>Level 1:</strong>&nbsp;
                                                Essential requirements with high user impact, normally with the least investment. These cover safety, color contrast, text alternatives, navigation, operation, and errors.
                                            </p>
                                            <br />
                                            <p><strong>Level 2:</strong>&nbsp;
                                                Adds to Level 1 the next-most important requirements that enable more users to fully use a product. These include pointer interaction, use of color, text style, auto-playing content, and responsive design.
                                            </p>
                                            <br />
                                            <p><strong>Level 3:</strong>&nbsp;
                                                The full set of IBM accessibility requirements that includes the Web Content Accessibility Guidelines (WCAG), US Revised 508, and European EN 301 549 requirements.
                                                <br /><br />
                                                <strong>Note:</strong> The <a href={withPrefix("/toolkit/plan/overview/#pace-of-completion")} target="_blank" rel="noopener noreferrer">Equal Access Toolkit</a> provides guidance only on WCAG requirements. It divides tasks and considerations by role involved in each phase into these three levels of progression for web applications.
                                            </p>
                                        </Modal>
                                    </div>
                                </div>
                            </div>

                        </div> {/*  END USER SELECTION BLOCK */}

                        <div className="bx--col-md-6 bx--offset-lg-3 dynamicHeaderReq4">{/* Accordions */}
                            <div style={{ height: "24px" }}>
                                {/* <div> */}
                                <span style={{ float: "right", height: "24px" }}>
                                    <span style={{ fontSize: "1.0rem", lineHeight: "24px" }}>{count} of {verCount} requirements selected</span><span style={{ paddingLeft: "8px" }}>|</span>
                                    <span style={{ marginTop: "0px" }}><Button onClick={this.expandCollapse} kind="ghost" size="sm" style={{ color: "#0F62FE", fontSize: "1.0rem", paddingLeft: "8px", verticalAlign: "baseline" }}>{this.state.expand === true ? "collapse all" : "expand all"}</Button></span>
                                </span>
                                {/* </div> */}
                            </div>
                            <div className="dynamicHeaderReq5">
                                <div>
                                    <Accordion align="start">

                                        {reqData.map((data, key) => {
                                            if (data.show === true) {
                                                let cpHash = data.requirement.split(" ")[0].replace(/[ .]/g, "_");
                                                return (
                                                    <TkAccordionItemCL key={cpHash} hash={cpHash} open={data.open} title={data.requirement}>
                                                        <div className="bx--row">
                                                            <p><Link
                                                                inline={true}
                                                                href={withPrefix(`/requirements/requirements/?version=${versionKey}#${cpHash}`)}
                                                                onClick={(evt) => {
                                                                    navigator.clipboard.writeText(`${document.location.origin}${document.location.pathname}?version=${versionKey}#${cpHash}`);
                                                                    document.location.href = `${document.location.origin}${document.location.pathname}?version=${versionKey}#${cpHash}`;
                                                                    evt.preventDefault();
                                                                }}
                                                            >Copy link for {data.requirement.split(" ")[0]} <Copy16 width={20} height={20} style={{ verticalAlign: "text-bottom" }} /></Link></p>
                                                        </div>
                                                        <ReqSwitcher reqNumber={cpHash} />
                                                    </TkAccordionItemCL>
                                                );
                                            }
                                        })}
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default ChecklistRequirements
