import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/IBMa/ibm.com-able-v2/able-site/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Column } from 'gatsby-theme-carbon/src/components/Grid';
import Launch16 from '@carbon/icons-react/lib/launch/16';
import ArrowRight16 from '@carbon/icons-react/lib/arrow--right/16';
import DocumentDownload16 from '@carbon/icons-react/lib/document--download/16';
import { withPrefix } from "gatsby";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Row = makeShortcode("Row");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Row mdxType="Row">
      <Column colLg={8} colMd={6} colSm={4} mdxType="Column">
        <DocumentDownload16 width={16} height={16} style={{
          verticalAlign: "bottom"
        }} mdxType="DocumentDownload16" />&nbsp;<a href="/able/a2cda17d264f4c889957fc2885011d2d/a_Web_Component.xlsx" type="application/vnd.ms-excel">Web Checklist in Excel format.xlsx</a><br />
        <DocumentDownload16 width={16} height={16} style={{
          verticalAlign: "bottom"
        }} mdxType="DocumentDownload16" />&nbsp;<a href="/able/dec4fe717e45f12c249ddf0934c54211/a_Documentation_Component_web_based.xlsx" type="application/vnd.ms-excel">Documentation Checklist in Excel format.xlsx</a><br />
        <DocumentDownload16 width={16} height={16} style={{
          verticalAlign: "bottom"
        }} mdxType="DocumentDownload16" />&nbsp;<a href="/able/4a218c429c6ffb24e89d0e7da4c893c1/an_Authoring_Tool_Component.xlsx" type="application/vnd.ms-excel">Authoring Tools Checklist in Excel format.xlsx</a><br />
        <DocumentDownload16 width={16} height={16} style={{
          verticalAlign: "bottom"
        }} mdxType="DocumentDownload16" />&nbsp;<a href="/able/3d34fe8e3ad3a24ffddbb191bbda7cc1/a_Software_Component.xlsx" type="application/vnd.ms-excel">Software (non-web) Checklist in Excel format.xlsx</a><br />
        <DocumentDownload16 width={16} height={16} style={{
          verticalAlign: "bottom"
        }} mdxType="DocumentDownload16" />&nbsp;<a href="/able/2ae7de3c26fccf7bd6306f933d1776ee/a_Documentation_Component_non_web_based.xlsx" type="application/vnd.ms-excel">Documentation (non-web) Checklist in Excel format.xlsx</a>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      