import { withPrefix } from "gatsby";
import Default from "../../../../src/gatsby-theme-carbon/templates/Default";
import { TkFooter } from "../../../../src/components/toolkit/TkFooter";
import { Row, Column } from 'gatsby-theme-carbon/src/components/Grid';
import spaceship from "../../../../src/images/spaceship.svg";
import touchScreen from "../../../../src/images/touch--screen.svg";
import build from "../../../../src/images/build.svg";
import expandVert from "../../../../src/images/expand--vert.svg";
import calendar from "../../../../src/images/calendar.svg";
import magNode from "../../../../src/images/npm.svg";
import magKarma from "../../../../src/images/karma.svg";
import magFirefox from "../../../../src/images/firefox.svg";
import magChrome from "../../../../src/images/chrome.png";
import github from "../../../../src/images/github.svg";
import model from "../../../../src/images/model.svg";
import figma from "../../../../src/images/figma.svg";
import bee from "../../../../src/images/bee.svg";
import edge from "../../../../src/images/edge.svg";
import cypress from "../../../../src/images/cypress.svg";
import report from "../../../../src/images/report.svg";
import ToolkitBanner from "../../../../src/images/ToolkitBanner.svg";
import launch from "../../../../src/images/launch.svg";
import locked from "../../../../src/images/locked.svg";
import ArrowRight16 from '@carbon/icons-react/lib/arrow--right/16';
import ibmLogo from "../../../../src/images/ibm_logo.svg";
import { Video } from 'gatsby-theme-carbon/src/components/Video';
import "../../../../src/pages/toolkit/tools.scss";
import "../../../../src/pages/toolkit/toolkit-resource-cards.scss";
import * as React from 'react';
export default {
  withPrefix,
  Default,
  TkFooter,
  Row,
  Column,
  spaceship,
  touchScreen,
  build,
  expandVert,
  calendar,
  magNode,
  magKarma,
  magFirefox,
  magChrome,
  github,
  model,
  figma,
  bee,
  edge,
  cypress,
  report,
  ToolkitBanner,
  launch,
  locked,
  ArrowRight16,
  ibmLogo,
  Video,
  React
};